<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h4 class="p-mt-1">ข้อมูลพนักงาน</h4>
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar>
                    <template #start>
                        <Button icon="pi pi-user-plus" label="เพิ่มพนักงาน" class="p-button-success mr-2" @click="openNew()" />
                        <InputText type="text" :placeholder="$t('common.username')" class="mr-2" v-model="state.usernameS" />
                    </template>

                    <template #end>
                        <Button icon="pi pi-search" label="ค้นหา" class="p-button-info mr-2" @click="getData(state.page, state.size)" />
                        <Button icon="pi pi-refresh" label="รีเซ็ต" class="p-button-warning" @click="reset()" />
                    </template>
                </Toolbar>
                <DataTable
                    :autoLayout="true"
                    :value="state.users"
                    :lazy="true"
                    stripedRows
                    :paginator="true"
                    :rows="50"
                    :totalRecords="state.totalRecords"
                    @page="onPage($event)"
                    :loading="state.loading"
                    :rowsPerPageOptions="[50, 100, 200]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    dataKey="id"
                    class="p-datatable-sm"
                >
                    <Column field="no" :header="$t('common.number')" :style="{ width: '100px', textAlign: 'center' }" headerClass="center">
                        <template #body="slotProps">
                            {{ slotProps.index + 1 }}
                        </template>
                    </Column>

                    <Column field="username" :header="$t('common.username')" sortable>
                        <template #body="slotProps">
                            <Button icon="pi pi-user" :label="slotProps.data.username" />
                        </template>
                    </Column>
                    <Column field="name" :header="$t('common.name')" sortable></Column>
                    <Column field="mobileNo" :header="$t('common.mobile')" :style="{ textAlign: 'center' }" headerClass="center" sortable></Column>
                    <Column field="role" header="ตำแหน่ง">
                        <template #body="{ data }">
                            {{ data.userType == 1 ? 'Admin' : '' }}
                        </template>
                    </Column>
                    <Column field="createDt" :header="$t('common.createdOn')" :style="{ textAlign: 'center' }" headerClass="center" sortable>
                        <template #body="{ data }">
                            {{ useDateFormat(data.createDt, 'YYYY-MM-DD HH:mm').value }}
                        </template>
                    </Column>
                    <Column field="status" :header="$t('common.status')" :sortable="true" :style="{ textAlign: 'center' }" headerClass="center">
                        <template #body="slotProps">
                            <Tag :severity="slotProps.data.status === 0 ? 'success' : 'danger'" :value="slotProps.data.status === 0 ? $t('common.statusY') : $t('common.suspend')" style="width: 150px; font-size: 14px"></Tag>
                        </template>
                    </Column>

                    <Column headerStyle="width: 12rem" headerClass="p-text-center" bodyClass="p-text-center">
                        <template #body="slotProps">
                            <Button icon="pi pi-key" class="p-button-rounded p-button-info m-1" @click="openEditPassword(slotProps.data)" />
                            <Button icon="pi pi-user-edit" class="p-button-rounded p-button-success m-1" @click="openEdit(slotProps.data)" />
                            <Button icon="pi pi-user-minus" class="p-button-rounded p-button-danger m-1" @click="deleteUser(slotProps.data)" />
                        </template>
                    </Column>
                    <template #footer> In total there are {{ formatInt(state.totalRecords) }} item. </template>
                </DataTable>
                <Dialog v-model:visible="state.editPassDialog" :header="$t('common.editpassword')" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '40vw' }">
                    <Message v-if="state.errorMessage" severity="error">{{ state.errorMessage }}</Message>
                    <div class="p-fluid">
                        <div class="field">
                            <label for="username">{{ $t('common.username') }}</label>
                            <InputText v-model="state.fromEdPass.username" :disabled="true" />
                        </div>
                        <div class="field">
                            <label for="password">รหัสผ่าน</label>
                            <Password v-model="state.fromEdPass.password.password" toggleMask :feedback="false" />
                        </div>
                        <div class="field">
                            <label for="confirmPassword">ยืนยันรหัสผ่าน</label>
                            <Password v-model="state.fromEdPass.password.confirm" toggleMask :feedback="false" />
                        </div>
                    </div>
                    <template #footer>
                        <Button :label="$t('common.btn.saveNew')" icon="pi pi-check" class="p-button-success" @click="saveEditPassword" autofocus></Button>
                        <Button label="ยกเลิก" icon="pi pi-times" class="p-button-danger" @click="closePassword()"></Button>
                    </template>
                </Dialog>
                <Dialog v-model:visible="state.usersDialog" header="ข้อมูลพนักงาน" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }">
                    <Message v-if="state.errorMessage" severity="error">{{ state.errorMessage }}</Message>
                    <div class="p-fluid">
                        <Panel :header="$t('common.dataDetail')">
                            <div class="field">
                                <label>{{ $t('common.username') }} *</label>
                                <InputText v-model="state.form.username" :disabled="state.form.mode == 'U'" />
                            </div>
                            <div class="field">
                                <label>{{ $t('common.name') }}</label>
                                <InputText v-model="state.form.name" />
                            </div>
                            <div class="field">
                                <label>{{ $t('common.mobile') }}</label>
                                <InputMask v-model="state.form.mobileNo" mask="099-999-9999" />
                            </div>
                        </Panel>
                        <Panel header="ความปลอดภัย" v-if="state.form.mode == 'A'">
                            <div class="field">
                                <label>{{ $t('common.password') }} *</label>
                                <Password v-model="state.form.password.password" toggleMask :feedback="false" />
                            </div>
                            <div class="field">
                                <label>{{ $t('common.confirmPassword') }} *</label>
                                <Password v-model="state.form.password.confirm" toggleMask :feedback="false" />
                            </div>
                        </Panel>
                    </div>
                    <template #footer>
                        <Button :label="$t('common.btn.saveNew')" icon="pi pi-check" class="p-button-success" @click="saveUser" autofocus></Button>
                        <Button label="ยกเลิก" icon="pi pi-times" class="p-button-danger" @click="close()"></Button>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, reactive } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import { useAuthStore } from '@/store/auth';
import UserService from '@/service/UserService';
import ComboService from '@/service/ComboService';
import { useRouter } from 'vue-router';
import { formatInt } from '@/common/Utils';
import { useDateFormat } from '@vueuse/shared';
import { useI18n } from 'vue-i18n';

const authUserStore = useAuthStore();
const router = useRouter();
const confirm = useConfirm();
const toast = useToast();
const { t } = useI18n();
const auth = computed(() => authUserStore.user);

const state = reactive({
    errorMessage: '',
    loading: false,
    usersDialog: false,
    editPassDialog: false,
    usernameS: '',
    users: [],
    authorityList: [],
    totalRecords: 0,
    page: 0,
    size: 50,
    form: {
        mode: '',
        id: '',
        username: '',
        name: '',
        password: {
            password: '',
            confirm: '',
        },
        createdBy: '',
        updatedBy: '',
        mobileNo: '',
        status: 0,
        role: null,
        authority: null,
        userType: 0,
        callcenterGroupId: null,
    },
    fromEdPass: {
        id: '',
        username: '',
        password: {
            password: '',
            confirm: '',
        },
        updatedBy: '',
    },
});

const getData = async (page, size) => {
    try {
        state.loading = true;
        const response = await UserService.findAllUser(state.usernameS, page, size);
        state.users = response.data.data;
        state.totalRecords = response.data.total;
    } catch (error) {
        if (error.response.status === 403) {
            router.push({ name: 'Login' });
        }
        //console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        // state.isLoading = false;
        state.loading = false;
    }
};

const onPage = (event) => {
    state.page = event.page;
    state.size = event.rows;
    getData(state.page, state.size);
};

const openEditPassword = async (user) => {
    try {
        state.errorMessage = '';
        state.fromEdPass.id = user.id;
        state.fromEdPass.username = user.username;
        state.fromEdPass.password.password = '';
        state.fromEdPass.password.confirm = '';
        state.editPassDialog = true;
    } catch (error) {
        ////console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

const saveEditPassword = async () => {
    try {
        if (state.fromEdPass.password.password == '') {
            state.errorMessage = 'กรุณาป้อนรหัสผ่าน';
            return;
        }
        if (state.fromEdPass.password.confirm == '') {
            state.errorMessage = 'กรุณาป้อนยืนยันรหัสผ่าน';
            return;
        }
        if (state.fromEdPass.password.password != state.fromEdPass.password.confirm) {
            state.errorMessage = 'ยืนยันรหัสผ่านไม่ตรง';
            return;
        }
        state.fromEdPass.updatedBy = auth.value.username;
        const { data: response } = await UserService.updateUserPassword(state.fromEdPass);
        if (response.status === 'OK') {
            toast.add({ severity: 'info', summary: t('common.process'), detail: t('common.complete.password'), life: 3000 });
            getData(state.page, state.size);
            state.editPassDialog = false;
        } else {
            toast.add({ severity: 'error', summary: t('common.process'), detail: t(response.code), life: 3000 });
        }
    } catch (error) {
        // //console.log(error);
        state.errorMessage = t('0999', [error.message]);
    }
};

const closePassword = async () => {
    state.editPassDialog = false;
};

const openNew = async () => {
    try {
        state.errorMessage = '';
        state.form.mode = 'A';
        state.form.username = '';
        state.form.name = '';
        state.form.mobileNo = '';
        state.form.role = 1;
        state.form.authority = 3;
        state.form.userType = 2;
        state.form.callcenterGroupId = null;
        state.form.password.password = '';
        state.form.password.confirm = '';
        state.usersDialog = true;
    } catch (error) {
        //console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

const openEdit = async (user) => {
    try {
        state.errorMessage = '';
        state.form.mode = 'U';
        state.form.id = user.id;
        state.form.username = user.username;
        state.form.name = user.name;
        state.form.mobileNo = user.mobileNo;
        //
        state.form.role = user.role;
        state.form.authority = user.authority;
        state.form.userType = user.userType;
        state.form.callcenterGroupId = user.callcenterGroupId?.callGroupId;
        //
        state.usersDialog = true;
    } catch (error) {
        // //console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

const saveUser = async () => {
    try {
        if (state.form.username == null || state.form.username == '') {
            state.errorMessage = 'กรุณาป้อนบัญชีผู้ใช้งาน';
            return;
        }
        if (state.form.name == null || state.form.name == '') {
            state.errorMessage = 'กรุณาป้อนชื่อ';
            return;
        }
        if (state.form.mode == 'A') {
            if (state.form.password.password == '') {
                state.errorMessage = 'กรุณาป้อนรหัสผ่าน';
                return;
            }
            if (state.form.password.confirm == '') {
                state.errorMessage = 'กรุณาป้อนยืนยันรหัสผ่าน';
                return;
            }
            if (state.form.password.password != state.form.password.confirm) {
                state.errorMessage = 'ยืนยันรหัสผ่านไม่ตรง';
                return;
            }
            state.form.createdBy = auth.value.username;
            const { data: response } = await UserService.saveUserNew(state.form);
            ////console.log(response);
            if (response.status === 'OK') {
                toast.add({ severity: 'info', summary: t('common.process'), detail: t('common.complete.add'), life: 3000 });
                resetForm();
                getData(state.page, state.size);
                state.usersDialog = false;
            } else {
                toast.add({ severity: 'error', summary: t('common.process'), detail: t(response.code), life: 3000 });
            }
        } else {
            state.form.updatedBy = auth.value.username;
            const { data: response } = await UserService.saveUserUpdate(state.form);
            ////console.log(response);
            if (response.status === 'OK') {
                toast.add({ severity: 'info', summary: t('common.process'), detail: t('common.complete.update'), life: 3000 });
                resetForm();
                getData(state.page, state.size);
                state.usersDialog = false;
            } else {
                toast.add({ severity: 'error', summary: t('common.process'), detail: t(response.code), life: 3000 });
            }
        }
    } catch (error) {
        // //console.log(error);
        state.errorMessage = t('0999', [error.message]);
    }
};

const deleteUser = async (user) => {
    confirm.require({
        message: 'คุณต้องการลบข้อมูลนี้ ใช่หรือไม่?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        yesLabel: 'xx',
        accept: async () => {
            const { data: response } = await UserService.removeUser(user);
            if (response.status === 'OK') {
                toast.add({ severity: 'info', summary: t('common.process'), detail: t('common.complete.delete'), life: 3000 });
                getData(state.page, state.size);
            } else {
                toast.add({ severity: 'error', summary: t('common.process'), detail: t(response.code), life: 3000 });
            }
        },
    });
};

const close = async () => {
    state.usersDialog = false;
};

const resetForm = () => {
    state.form.mobileNo = '';
    state.form.username = '';
};

const getCombo = async () => {
    try {
        const response2 = await ComboService.findAuthorityAll();
        state.authorityList = response2.data.data;
    } catch (error) {
        if (error.response.status === 403) {
            router.push({ name: 'Login' });
        }
        //console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        // state.isLoading = false;
        state.loading = false;
    }
};

const reset = async () => {
    state.usernameS = '';
    getData(state.page, state.size);
};

onMounted(() => {
    getData(state.page, state.size);
    getCombo();
});
</script>
<style lang="scss">
.center {
    .p-column-header-content {
        text-align: center;
        display: block !important;
    }
}

.right {
    .p-column-header-content {
        text-align: right;
        display: block !important;
    }
}
</style>
