import api from './api';

class UserService {
    findAll(page, size) {
        return api.get(`/api/v1/users/findAgentAndUser?page=${page}&size=${size}`);
    }

    findAllUser(username, page, size) {
        return api.get(`/api/v1/users/findUserAll?username=${username}&page=${page}&size=${size}`);
    }

    findUserLevelAll() {
        return api.get('/api/v1/user-levels/findAll');
    }

    findUserLevel(id) {
        return api.get(`/api/v1/user-levels/findUserLevel?id=${id}`);
    }

    saveUserLevelNew(userLevel) {
        //console.log(JSON.stringify(userLevel));
        return api.post('/api/v1/user-levels/saveUserLevelNew', JSON.stringify(userLevel));
    }

    saveUserLevelUpdate(userLevel) {
        return api.put(`/api/v1/user-levels/saveUserLevelUpdate`, JSON.stringify(userLevel));
    }

    findUserGroupAll() {
        return api.get('/api/v1/user-groups/findUserGroupAll');
    }
    findUserGroup(id) {
        return api.get(`/api/v1/user-groups/findUserGroup?id=${id}`);
    }
    saveUserGroupNew(userGroup) {
        return api.post('/api/v1/user-groups/saveUserGroupNew', JSON.stringify(userGroup));
    }
    saveUserGroupUpdate(userGroup) {
        return api.put('/api/v1/user-groups/saveUserGroupUpdate', JSON.stringify(userGroup));
    }
    deleteUserGroup(id) {
        return api.delete(`/api/v1/user-groups/deleteUserGroup/${id}`);
    }

    saveUserNew(user) {
        // alert(JSON.stringify(user));
        return api.post('/api/v1/users/saveUserNew', JSON.stringify(user));
    }

    saveUserUpdate(user) {
        return api.post('/api/v1/users/saveUserUpdate', JSON.stringify(user));
    }

    updateUserPassword(user) {
        return api.post('/api/v1/users/updateUserPassword', JSON.stringify(user));
    }

    findUser(id) {
        return api.get(`/api/v1/users/findUser?id=${id}`);
    }

    findUserDetailByUserId(id) {
        return api.get(`/api/v1/users/findUserDetailByUserId?id=${id}`);
    }

    removeUser(user) {
        return api.post('/api/v1/users/delete', JSON.stringify(user));
    }

    findMemberAll(username, page, size) {
        return api.get(`/api/v1/users/findMemberAll?username=${username}&page=${page}&size=${size}`);
    }
}
export default new UserService();
